import { SUPERSHIP_ICON } from '~/constants';

export interface FilterItem {
  title: string;
  value: string | number | boolean;
  bool?: boolean;
}

export interface FilterType {
  id: string;
  title: string;
  values: Array<FilterItem>;
}

export type FilterConfig = Array<FilterType>;

export interface FilterMap {
  [key: string]: string;
}

export const filterPathMap: FilterMap = {
  level: 'level',
  nation: 'nation',
  class: 'type',
  premium: 'isPremium',
  special: 'isSpecial',
};

export const getFilterConfig = (
  nationsMap: NationsMap,
  vehicleTypesMap: VehicleTypesMap,
  t: (str: string) => string,
): FilterConfig => {
  const nationValues: Array<FilterItem> = [];

  for (const key in nationsMap) {
    nationValues.push({
      title: nationsMap[key].title,
      value: nationsMap[key].name,
    });
  }

  const vehicleTypeValues: Array<FilterItem> = [];

  for (const key in vehicleTypesMap) {
    vehicleTypeValues.push({
      title: vehicleTypesMap[key].title,
      value: vehicleTypesMap[key].name,
    });
  }

  const filterConfig = [
    {
      id: 'nation.name',
      title: t('Нация'),
      values: nationValues,
    },
    {
      id: 'type.name',
      title: t('Класс'),
      values: vehicleTypeValues,
    },
    {
      id: 'level',
      title: t('Уровень'),
      values: [
        { title: 'I', value: 1 },
        { title: 'II', value: 2 },
        { title: 'III', value: 3 },
        { title: 'IV', value: 4 },
        { title: 'V', value: 5 },
        { title: 'VI', value: 6 },
        { title: 'VII', value: 7 },
        { title: 'VIII', value: 8 },
        { title: 'IX', value: 9 },
        { title: 'X', value: 10 },
        { title: SUPERSHIP_ICON, value: 11 },
      ],
    },
    {
      id: 'ship_type',
      title: t('Тип техники'),
      values: [
        {
          title: t('Премиумный'),
          value: 'isPremium',
          bool: true,
        },
        {
          title: t('Особый'),
          value: 'isSpecial',
          bool: true,
        },
      ],
    },
  ];

  return filterConfig;
};
