import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { StatisticsColumn } from '~/Components/Statistics/Statistics';
import { StatisticsHeaderItem } from '~/Components/StatisticsHeader/StatisticsHeader';
import {
  BATTLE_TYPES,
  PVP_BATTLE_TYPES,
  RANK_BATTLE_TYPES,
  RANK_OLD_BATTLE_TYPES,
  SEASONAL_BATTLE_TYPES,
} from '~/constants';
import { roundNumbers } from '~/utils';

import { getBattleType } from './glossary';

export const getStatisticsHeaderData = (statistics: BattleStat): Array<StatisticsHeaderItem> => {
  const { t } = useTranslation();

  const deadCount = statistics.battles_count - statistics.survived;

  const statisticsData = [
    {
      value: roundNumbers(statistics.battles_count),
      label: t('Проведено боёв'),
    },
    {
      value: roundNumbers((100 / statistics.battles_count) * statistics.wins, true),
      label: t('Победы'),
    },
    {
      value: roundNumbers(statistics.premium_exp / statistics.battles_count),
      label: t('Опыт'),
    },
    {
      value: roundNumbers(statistics.max_frags),
      label: t('Кораблей за бой'),
    },
    {
      value: deadCount ? roundNumbers(statistics.frags / deadCount) : 0,
      label: t('Уничтожил / уничтожен'),
    },
  ];
  return statisticsData;
};

export const getStatisticsData = (statistics: BattleStat): Array<StatisticsColumn> => {
  const { t } = useTranslation();

  const winsPercent = (100 / statistics.battles_count) * statistics.wins;
  const lossesPercent = (100 / statistics.battles_count) * statistics.losses;
  const draws = statistics.battles_count - statistics.wins - statistics.losses;
  const mainBatteryHitsPercent = (100 / statistics.shots_by_main) * statistics.hits_by_main;
  const torpedoesHitsPercent = (100 / statistics.shots_by_tpd) * statistics.hits_by_tpd;
  const capturedPercent = (statistics.control_captured_points / statistics.team_control_captured_points) * 100;
  const droppedPercent = (statistics.control_dropped_points / statistics.team_control_dropped_points) * 100;
  const fireAndFlooding =
    statistics.frags -
    statistics.frags_by_tpd -
    statistics.frags_by_planes -
    statistics.frags_by_main -
    statistics.frags_by_atba -
    statistics.frags_by_ram;
  const averageFrags = statistics.frags / statistics.battles_count;
  const averagePlanesKilled = statistics.planes_killed / statistics.battles_count;
  const averageDamageDealt = statistics.damage_dealt / statistics.battles_count;
  const averageXp = statistics.premium_exp / statistics.battles_count;
  const averageShipsSpotted = statistics.ships_spotted / statistics.battles_count;
  const averageScoutingDamage = statistics.scouting_damage / statistics.battles_count;
  const averageAgro = (statistics.art_agro + statistics.tpd_agro) / statistics.battles_count_510;
  const agroTotal = statistics.art_agro + statistics.tpd_agro;

  const leftColumn: StatisticsColumn = [
    [
      {
        label: t('Проведено боёв'),
        value: roundNumbers(statistics.battles_count),
      },
      {
        label: t('Побед'),
        value: [roundNumbers(winsPercent, true), roundNumbers(statistics.wins)],
      },
      {
        label: t('Поражений'),
        value: [roundNumbers(lossesPercent, true), roundNumbers(statistics.losses)],
      },
      {
        label: t('Ничьих'),
        value: roundNumbers(draws),
      },
      {
        label: t('Выжил в боях'),
        value: roundNumbers(statistics.survived),
        spacer: true,
      },
      {
        label: t('Процент попадания ГК'),
        value: roundNumbers(mainBatteryHitsPercent, true),
      },
      {
        label: t('Процент попадания торпедами'),
        value: roundNumbers(torpedoesHitsPercent, true),
        spacer: true,
      },
      {
        label: t('Участие в захвате'),
        value: roundNumbers(capturedPercent, true),
      },
      {
        label: t('Участие в защите'),
        value: roundNumbers(droppedPercent, true),
        spacer: true,
      },
      {
        label: t('Нанесено урона'),
        value: roundNumbers(statistics.damage_dealt),
      },
      {
        label: t('Опыт'),
        value: roundNumbers(statistics.premium_exp),
      },
    ],
  ];
  const middleColumn: StatisticsColumn = [
    [
      {
        label: t('Уничтожено кораблей'),
        value: roundNumbers(statistics.frags),
      },
      {
        label: t('Главным калибром'),
        value: roundNumbers(statistics.frags_by_main),
      },
      {
        label: t('Вспомогательным калибром'),
        value: roundNumbers(statistics.frags_by_atba),
      },
      {
        label: t('Торпедами'),
        value: roundNumbers(statistics.frags_by_tpd),
      },
      {
        label: t('Авиацией'),
        value: roundNumbers(statistics.frags_by_planes),
      },
      {
        label: t('Пожарами и затоплением'),
        value: roundNumbers(fireAndFlooding),
      },
      {
        label: t('Тараном'),
        value: roundNumbers(statistics.frags_by_ram),
      },
      {
        label: t('Глубинными бомбами'),
        value: roundNumbers(statistics.frags_by_dbomb),
      },
      {
        label: t('Минами'),
        value: roundNumbers(statistics.frags_by_mines),
        spacer: true,
      },
      {
        label: t('Уничтожено самолетов'),
        value: roundNumbers(statistics.planes_killed),
        spacer: true,
      },
      {
        label: t('Обнаружено кораблей'),
        value: roundNumbers(statistics.ships_spotted),
      },
      {
        label: t('Урон по обнаружению'),
        value: roundNumbers(statistics.scouting_damage),
        spacer: true,
      },
      {
        label: t('Потенциальный урон'),
        value: roundNumbers(agroTotal),
        spacer: true,
      },
    ],
  ];
  const rightColumn: StatisticsColumn = [
    [
      {
        label: t('Средние показатели за бой'),
        value: '',
      },
      {
        label: t('Уничтожено кораблей'),
        value: roundNumbers(averageFrags),
      },
      {
        label: t('Уничтожено самолетов'),
        value: roundNumbers(averagePlanesKilled),
      },
      {
        label: t('Нанесено урона'),
        value: roundNumbers(averageDamageDealt),
      },
      {
        label: t('Опыт'),
        value: roundNumbers(averageXp),
      },
      {
        label: t('Обнаружено кораблей'),
        value: roundNumbers(averageShipsSpotted),
      },
      {
        label: t('Урон по обнаружению'),
        value: roundNumbers(averageScoutingDamage),
      },
      {
        label: t('Потенциальный урон'),
        value: roundNumbers(averageAgro),
      },
    ],

    [
      {
        label: t('Максимум за бой'),
        value: '',
      },
      {
        label: t('Уничтожено кораблей'),
        value: roundNumbers(statistics.max_frags),
      },
      {
        label: t('Уничтожено самолетов'),
        value: roundNumbers(statistics.max_planes_killed),
      },
      {
        label: t('Нанесено урона'),
        value: roundNumbers(statistics.max_damage_dealt),
      },
      {
        label: t('Опыт'),
        value: roundNumbers(statistics.max_premium_exp),
      },
      {
        label: t('Обнаружено кораблей'),
        value: roundNumbers(statistics.max_ships_spotted),
      },
      {
        label: t('Урон по обнаружению'),
        value: roundNumbers(statistics.max_scouting_damage),
      },
      {
        label: t('Потенциальный урон'),
        value: roundNumbers(statistics.max_total_agro),
      },
    ],
  ];

  const data: Array<StatisticsColumn> = [leftColumn, middleColumn, rightColumn];
  return data;
};

export const getAvailableBattleTypes = (account?: Account, allTypes?: boolean): BattleTypeName[] => {
  const nonBattleTypesKeys = ['basic', 'seasons', 'rank_info'];

  if (!account || !account.statistics || Object.keys(account.statistics).length === 0 || allTypes) {
    return BATTLE_TYPES.map((battleType) => battleType.id);
  }

  const availableBattleTypes: Set<BattleTypeName> = new Set();

  if (account.statistics) {
    for (const key in account.statistics) {
      if (!nonBattleTypesKeys.includes(key) && !SEASONAL_BATTLE_TYPES.includes(key)) {
        const val = get(account.statistics, key, undefined);
        if (val && Object.keys(val).length > 0) {
          if (RANK_OLD_BATTLE_TYPES.includes(key)) availableBattleTypes.add('rank_old' as BattleTypeName);
          else if (RANK_BATTLE_TYPES.includes(key)) availableBattleTypes.add('rank' as BattleTypeName);
          else if (PVP_BATTLE_TYPES.includes(key)) availableBattleTypes.add('pvp' as BattleTypeName);
          else availableBattleTypes.add(key as BattleTypeName);
        }
      }
      if (key === 'seasons') {
        const val = get(account.statistics, key, undefined);
        if (val && Object.keys(val).length > 0) {
          for (const seasonKey in val) {
            Object.keys(val[seasonKey]).forEach((seasonBattleType: BattleTypeName) => {
              if (!availableBattleTypes.has(seasonBattleType)) {
                if (RANK_OLD_BATTLE_TYPES.includes(seasonBattleType))
                  availableBattleTypes.add('rank_old' as BattleTypeName);
                else if (RANK_BATTLE_TYPES.includes(seasonBattleType))
                  availableBattleTypes.add('rank' as BattleTypeName);
                else availableBattleTypes.add(seasonBattleType);
              }
            });
          }
        }
      }
    }
  }

  return Array.from(availableBattleTypes);
};

export const getAvailableStatBattleTypes = (account?: Account): StatBattleType[] => {
  const nonBattleTypesKeys = ['basic', 'seasons', 'rank_info'];

  const availableBattleTypes: Set<StatBattleType> = new Set();

  if (account.statistics) {
    for (const key in account.statistics) {
      if (!nonBattleTypesKeys.includes(key) && !SEASONAL_BATTLE_TYPES.includes(key)) {
        const val = get(account.statistics, key, undefined);
        if (val && Object.keys(val).length > 0) {
          availableBattleTypes.add(key as StatBattleType);
        }
      }
      if (key === 'seasons') {
        const val = get(account.statistics, key, undefined);
        if (val && Object.keys(val).length > 0) {
          for (const seasonKey in val) {
            Object.keys(val[seasonKey]).forEach((seasonBattleType: StatBattleType) => {
              if (!availableBattleTypes.has(seasonBattleType)) {
                availableBattleTypes.add(seasonBattleType);
              }
            });
          }
        }
      }
    }
  }
  return Array.from(availableBattleTypes);
};

export const getAvailableDivisions = (
  battleTypeRaw: BattleTypeName,
  divisions: DivisionName[],
  availableBattleTypes: StatBattleType[],
): DivisionName[] => {
  const availableDivisions: DivisionName[] = [];
  divisions.forEach((division) => {
    const battleType = getBattleType(battleTypeRaw, division);
    if (availableBattleTypes.includes(battleType)) {
      availableDivisions.push(division);
    }
  });
  return availableDivisions;
};
