import Nav from '@wg/wows-react-uikit/Nav';
import equal from 'fast-deep-equal/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { navigateToRoute } from '~/Actions/ActionNavigation';
import { playButtonClickSound } from '~/helpers/audioApi';
import { State } from '~/Reducers';
import { hideScrollThumb } from '~/scroll';

import styles from './Menu.scss';

export interface MenuRoute {
  path: string;
  title: string;
}

export interface ProfileState {
  spaId?: string;
}

const stateSelector = (state: State): ProfileState => {
  return {
    spaId: state.ReducerApp.spaId,
  };
};

const Menu = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedPath, setSelectedPath] = useState('/');
  const dispatch = useDispatch();
  const state = useSelector<State, ProfileState>(stateSelector, equal);
  const { spaId } = state;

  const onRouteClick = (route: string) => {
    playButtonClickSound();

    dispatch(
      navigateToRoute(route, () => {
        setSelectedPath(route);
        window.scrollTo(0, 0);
        hideScrollThumb();
      }),
    );
  };

  useEffect(() => {
    const root = location.pathname.split('/')[1];
    setSelectedPath(`/${root}`);
  }, [location]);

  const routes: Array<MenuRoute> = [
    {
      path: '/',
      title: t('Поиск игрока'),
    },
  ];

  if (spaId) {
    routes.push({
      path: '/achievements',
      title: t('Достижения'),
    });
    routes.push({
      path: '/statistics',
      title: t('Сводка'),
    });
  }

  return (
    <div className={styles.menu}>
      <Nav onRouteClick={onRouteClick} root="" routes={routes} selectedPath={selectedPath} />
    </div>
  );
};

export default Menu;
