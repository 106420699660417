import '@wg/wows-css-uikit/index.css';
import '~/styles/global.scss';

import { setWoWsEntitiesSettings } from '@wg/wows-entities/core/WoWsEntitiesSettings';
import TooltipProvider from '@wg/wows-react-uikit/TooltipProvider';
import React from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { generatePath, Redirect, Route, Router, Switch } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import { StickyContainer } from 'react-sticky';
import { applyMiddleware, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { Actions } from '~/Actions';
import history from '~/browserHistory';
import Background from '~/Components/Background/Background';
import { Container } from '~/Components/Grid/Grid';
import Init from '~/Components/Init/Init';
import Menu from '~/Components/Menu/Menu';
import MenuSpacer from '~/Components/Menu/MenuSpacer';
import Notifications from '~/Components/Notifications/Notifications';
import Achievements from '~/Components/Pages/Achievements/Achievements';
import Profile from '~/Components/Pages/Profile/Profile';
import Search from '~/Components/Pages/Search/Search';
import Spinner from '~/Components/Spinner/Spinner';
import ToTopArrow from '~/Components/ToTopArrow/ToTopArrow';
import toTopButtonStyles from '~/Components/ToTopArrow/ToTopArrow.scss';
import { GLOSS, ROUTES } from '~/constants';
import i18n from '~/i18n';
import persistMiddleware from '~/middlewares/persistMiddleware';
import preloaded from '~/preloaded';
import reducer, { State } from '~/Reducers';

import CookieButton from './Components/CookieButton/CookieButton';

const woWsEntitiesSettings = {
  vortexUrl: `${preloaded.urls.vortex}${GLOSS}`,
  languageCode: preloaded.settings.languageCode,
};

setWoWsEntitiesSettings(woWsEntitiesSettings);

const middleware = [thunk as ThunkMiddleware<State, Actions>];
middleware.push(persistMiddleware);

export const store = createStore(reducer, applyMiddleware(...middleware));

const routes = [
  {
    path: ROUTES.PROFILE_ACCESS_CODE,
    View: Profile,
  },
  {
    path: ROUTES.PROFILE,
    View: Profile,
  },
  {
    path: ROUTES.ACHIEVEMENTS,
    View: Achievements,
  },
  {
    path: ROUTES.ROOT,
    View: Search,
  },
];

const Root = () => {
  const { t } = useTranslation();
  document.title = t('Профиль игрока');

  return (
    <>
      <Background />
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <TooltipProvider>
            <StickyContainer>
              <Router history={history}>
                <MenuSpacer>
                  <Container>
                    <Init />
                    <Switch>
                      {routes.map(({ path, View }) => (
                        <Route key={path} exact path={path}>
                          <View />
                        </Route>
                      ))}
                      <Redirect
                        to={
                          window.preloaded.account
                            ? generatePath(ROUTES.PROFILE, { id: window.preloaded.account.id })
                            : ROUTES.ROOT
                        }
                      />
                    </Switch>
                  </Container>
                </MenuSpacer>
                <Menu />
                <Spinner />
                <CookieButton />
              </Router>
            </StickyContainer>
          </TooltipProvider>
          <Notifications />
        </I18nextProvider>
        <ScrollToTop smooth component={<ToTopArrow />} className={toTopButtonStyles.button} />
      </Provider>
    </>
  );
};

export default Root;
