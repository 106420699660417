import Interpolate from '@wg/wows-react-uikit/Interpolate';
import TooltipBody from '@wg/wows-react-uikit/TooltipBody';
import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BarChart from '~/Components/BarChart/BarChart';
import Flag from '~/Components/Flag/Flag';
import { Col, Container, Row } from '~/Components/Grid/Grid';
import PieChart from '~/Components/PieChart/PieChart';
import ShipClass from '~/Components/ShipClass/ShipClass';
import { State } from '~/Reducers';
import { getEmptyBarStats } from '~/utils';
import { formatShipLevel } from '~/utils/formatting';
import { isSupership } from '~/utils/ships';

import styles from './Charts.scss';

export interface ProfileState {
  nationStatisticsMap: NationsStatisticsMap;
  battleType: StatBattleType;
  nationsMap: NationsMap;
  vehicleTypeStatisticsMap: VehicleTypeStatisticsMap;
  vehicleLevelStatisticsMap: VehicleLevelStatisticsMap;
  vehicleTypesMap: VehicleTypesMap;
  seasonPickerVisible: boolean;
  seasonNumber?: string;
}

const stateSelector = (state: State): ProfileState => {
  return {
    nationStatisticsMap: state.ReducerApp.nationStatisticsMap,
    battleType: state.ReducerApp.battleType,
    nationsMap: state.ReducerApp.nationsMap,
    vehicleTypeStatisticsMap: state.ReducerApp.vehicleTypeStatisticsMap,
    vehicleLevelStatisticsMap: state.ReducerApp.vehicleLevelStatisticsMap,
    vehicleTypesMap: state.ReducerApp.vehicleTypesMap,
    seasonPickerVisible: state.ReducerApp.seasonPickerVisible,
    seasonNumber: state.ReducerApp.seasonNumber,
  };
};

const vehicleColorsMap = {
  aircarrier: '#f3bd7f',
  battleship: '#d14842',
  cruiser: '#3497da',
  destroyer: '#cfd4d8',
  submarine: '#808b8d',
};

const Charts = () => {
  const { t } = useTranslation();
  const state = useSelector<State, ProfileState>(stateSelector, equal);
  const [pieChartDataNations, setPieChartDataNations] = useState([]);
  const [pieChartDatavehicleType, setPieChartDataVehicleType] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const {
    battleType,
    nationStatisticsMap,
    nationsMap,
    vehicleTypeStatisticsMap,
    vehicleLevelStatisticsMap,
    vehicleTypesMap,
    seasonPickerVisible,
    seasonNumber,
  } = state;

  useEffect(() => {
    const nationsdata = [];
    for (const key in state.nationStatisticsMap) {
      let val = get(state.nationStatisticsMap, `${key}.${battleType}`, 0);
      if (seasonPickerVisible) {
        val = get(state.nationStatisticsMap, `${key}.seasons.${seasonNumber}.${battleType}`, 0);
      }
      if (val > 0) {
        const color = nationsMap[key].color.replace('#bffcc00', '#ffcc00');
        nationsdata.push({
          value: val,
          title: nationsMap[key].title,
          color: color,
          name: key,
        });
      }
    }
    setPieChartDataNations(nationsdata);

    const vehicleTypeData = [];
    for (const key in vehicleTypeStatisticsMap) {
      let val = get(vehicleTypeStatisticsMap, `${key}.${battleType}`, 0);
      if (seasonPickerVisible) {
        val = get(vehicleTypeStatisticsMap, `${key}.seasons.${seasonNumber}.${battleType}`, 0);
      }

      if (val > 0) {
        vehicleTypeData.push({
          value: val,
          title: key,
          // @ts-ignore
          color: vehicleColorsMap[key],
        });
      }
    }
    setPieChartDataVehicleType(vehicleTypeData);

    const barChartData: Array<any> = [];

    const emptyStats = {
      ...getEmptyBarStats(),
      ...vehicleLevelStatisticsMap,
    };

    for (const key in emptyStats) {
      let val = get(vehicleLevelStatisticsMap, `${key}.${battleType}`, 0);
      if (seasonPickerVisible) {
        val = get(vehicleLevelStatisticsMap, `${key}.seasons.${seasonNumber}.${battleType}`, 0);
      }
      const shipLevel = parseInt(key, 10);
      barChartData.push({
        value: val,
        label: formatShipLevel(shipLevel),
        shipLevel,
        fontSize: isSupership(shipLevel) ? 16 : 14,
      });
    }

    setBarChartData(barChartData);
  }, [battleType, nationStatisticsMap, vehicleTypeStatisticsMap, seasonPickerVisible, seasonNumber]);

  const renderPieChartNationItem = (index: number) => {
    const name = pieChartDataNations[index].name;
    return (
      <div className={styles.legendItem}>
        <Flag nation={name} />
      </div>
    );
  };

  const renderPieChartNationItemTooltip = (index: number) => {
    const nation = pieChartDataNations[index].title;
    return (
      <TooltipBody>
        <Interpolate
          str={t('%(battles)s боев на кораблях %(nation)s', {
            count: pieChartDataNations[index].value,
          })}
          battles={pieChartDataNations[index].value}
          nation={nation}
        />
      </TooltipBody>
    );
  };

  const renderPieChartShipItem = (index: number) => {
    return (
      <div className={styles.legendItem}>
        <ShipClass type={pieChartDatavehicleType[index].title} />
      </div>
    );
  };

  const renderPieChartShipItemTooltip = (index: number) => {
    const title = vehicleTypesMap[pieChartDatavehicleType[index].title].title;

    return (
      <TooltipBody>
        <Interpolate
          str={t('%(battles)s боев на классе %(vehicleType)s', {
            count: pieChartDatavehicleType[index].value,
          })}
          battles={pieChartDatavehicleType[index].value}
          vehicleType={title}
        />
      </TooltipBody>
    );
  };

  const renderBarChartItemTooltip = (index: number) => {
    if (barChartData[index].value === 0) {
      return <TooltipBody>{t('Нет боёв')}</TooltipBody>;
    }
    return isSupership(barChartData[index].shipLevel) ? (
      <TooltipBody>
        <Interpolate
          str={t('%(battles)s боев на суперкораблях', {
            count: barChartData[index].value,
          })}
          battles={barChartData[index].value}
        />
      </TooltipBody>
    ) : (
      <TooltipBody>
        <Interpolate
          str={t('%(battles)s боев на кораблях %(level)s уровня', {
            count: barChartData[index].value,
          })}
          battles={barChartData[index].value}
          level={barChartData[index].label}
        />
      </TooltipBody>
    );
  };

  return (
    <Container className={styles.wrapper}>
      <Row>
        <Col size={4}>
          <div className={styles.title}>{t('Нации')}</div>
          <div className={styles.chart}>
            <PieChart
              renderLegendItem={renderPieChartNationItem}
              renderItemTooltip={renderPieChartNationItemTooltip}
              data={pieChartDataNations}
            />
          </div>
        </Col>
        <Col size={4}>
          <div className={styles.title}>{t('Классы')}</div>
          <div className={styles.chart}>
            <PieChart
              renderLegendItem={renderPieChartShipItem}
              renderItemTooltip={renderPieChartShipItemTooltip}
              data={pieChartDatavehicleType}
            />
          </div>
        </Col>
        <Col size={4}>
          <div className={styles.title}>{t('Уровни')}</div>
          <div className={styles.chart}>
            <BarChart renderItemTooltip={renderBarChartItemTooltip} data={barChartData} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Charts;
