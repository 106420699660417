import equal from 'fast-deep-equal/react';
import { get as lGet } from 'lodash/fp';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getAccount, setAchievementsSortOrder } from '~/Actions/ActionApp';
import AchievementGroup from '~/Components/AchievementGroup/AchievementGroup';
import { Col, Container, Row } from '~/Components/Grid/Grid';
import Hidden from '~/Components/Hidden/Hidden';
import NotFound from '~/Components/NotFound/NotFound';
import Header from '~/Components/ProfileHeader/ProfileHeader';
import Select from '~/Components/Select/Select';
import { ACHIEVEMENT_GROUP_TYPES, ACHIEVEMENT_SORT_ORDER } from '~/constants';
import { useWillMount } from '~/hooks';
import { State } from '~/Reducers';

import styles from './Achievements.scss';

export interface AchievementsProps {
  noNavigation?: boolean;
}

export interface AchievementsState {
  account?: Account;
  allAchievements: Array<Achievements>;
  allAchievementGroups: Array<AchievementsGroup>;
  achievements: AchievementsAccountStatisticsAchievements;
  fetching: FetchingMap;
  achievementsSortOrder: AchievementsSortOrder;
}

const stateSelector = (state: State): AchievementsState => {
  const achievements = state.ReducerApp.encyclopedia.achievements || [];
  const all_achievements_groups = state.ReducerApp.encyclopedia.achievementsGroups || [];
  return {
    account: state.ReducerApp.account,
    allAchievements: achievements
      .filter((e) => {
        return e.grouping.group !== ACHIEVEMENT_GROUP_TYPES.NOT_USED;
      })
      .sort((a, b) => {
        return parseInt(b.id) - parseInt(a.id);
      }),
    allAchievementGroups: all_achievements_groups.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    }),
    achievements: state.ReducerApp.achievements,
    fetching: state.ReducerApp.fetching,
    achievementsSortOrder: state.ReducerApp.achievementsSortOrder,
  };
};

const Achievements = (props: AchievementsProps) => {
  const params = useParams<LocationParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector<State, AchievementsState>(stateSelector, equal);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { t } = useTranslation();
  const { account, achievements, allAchievements, allAchievementGroups, fetching, achievementsSortOrder } = state;

  useWillMount(() => {
    if (params.id && !account) {
      dispatch(getAccount(params.id, params.access_code));
    }
  });

  useEffect(() => {
    if (params.sortorder && params.sortorder !== achievementsSortOrder) {
      dispatch(setAchievementsSortOrder(params.sortorder));
    }
  }, [params]);

  if (params.id && account && account.id !== params.id) {
    dispatch(getAccount(params.id, params.access_code));
  }

  if (fetching.account) {
    return null;
  }

  if (!account && fetching.account === false) {
    return <NotFound />;
  }

  const isHidden = account ? account.hidden_profile : false;

  if (isHidden) {
    return <Hidden />;
  }

  const achievementsSortOrderTitles = {
    default: t('по умолчанию'),
    date: t('по времени получения'),
    count: t('по количеству'),
  };

  const achievementsSortOrders = ACHIEVEMENT_SORT_ORDER.map((achievementsSortOrderListItem) => {
    return {
      title: achievementsSortOrderTitles[achievementsSortOrderListItem.id],
      value: achievementsSortOrderListItem.id,
    };
  });

  let achievementsSortOrderIndex = 0;
  achievementsSortOrders.forEach((achievementsSortOrderItem, index) => {
    if (achievementsSortOrderItem.value === achievementsSortOrder) {
      achievementsSortOrderIndex = index;
    }
  });

  if (achievementsSortOrderIndex !== selectedIndex) {
    setSelectedIndex(achievementsSortOrderIndex);
  }

  const onSelectHandler = (index: number) => {
    setSelectedIndex(index);
    const value = lGet([index, 'value'], achievementsSortOrders);
    if (props.noNavigation) {
      dispatch(setAchievementsSortOrder(value as AchievementsSortOrder));
    } else {
      if (value && params.id) {
        history.push(`/achievements/${params.id}/${value}`);
      }
    }
  };

  const renderValue = () => {
    const title = achievementsSortOrders[selectedIndex] ? achievementsSortOrders[selectedIndex].title : ' ';
    return <div className={styles.value}>{title}</div>;
  };

  const renderItem = (index: number) => {
    return achievementsSortOrders[index].title;
  };

  return (
    <React.Fragment>
      <div className={styles.header}>
        <Header />
      </div>
      <Container>
        <Row>
          <Col size={0} />
          <Col size={3} className={styles.pickerRow}>
            <Select
              maxItemsCount={achievementsSortOrders.length}
              items={achievementsSortOrders.map((item) => item.value)}
              selectedIndex={achievementsSortOrderIndex}
              onSelect={onSelectHandler}
              renderValue={renderValue}
              renderItem={renderItem}
            />
          </Col>
        </Row>
      </Container>
      <div>
        {allAchievementGroups.map((achievementGroup) => {
          return (
            <AchievementGroup
              {...achievementGroup}
              allAchievements={allAchievements}
              accountAchievements={achievements}
              achievementsSortOrder={achievementsSortOrder}
              key={`AchievementGroup-${achievementGroup.id}`}
            />
          );
        })}
      </div>
      <div className={styles.allGroupAchievementsBottomIndent} />
    </React.Fragment>
  );
};

export default memo(Achievements);
