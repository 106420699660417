import equal from 'fast-deep-equal/react';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StickyContainer } from 'react-sticky';

import Header from './Header';
import Row from './Row';
import styles from './Table.scss';

export interface BasicColumn {
  selector?: string;
}

export interface Table_Props<Item, Column> {
  items: Array<Item>;
  columns: Array<Column>;
  sort?: string;
  sortDirection?: SortDirection;
  topOffset: number;
  onRowClick: (item: Item, index: number) => void;
  onHeaderCellClick?: (column: Column) => void;
  renderCell: (column: Column, index: number) => React.ReactNode;
  renderHeaderCell: (column: Column) => React.ReactNode;
  renderAfter?: (index: number) => React.ReactNode;
  onHeaderStickyStateChange?: (isSticky: boolean) => void;
}

const Table = <Item extends object, Column extends BasicColumn>(props: Table_Props<Item, Column>) => {
  const {
    items,
    columns,
    sort,
    sortDirection,
    topOffset,
    renderCell,
    renderHeaderCell,
    onHeaderCellClick,
    onHeaderStickyStateChange,
    renderAfter,
  } = props;

  const { t } = useTranslation();

  const onRowClick = (item: Item, rowIndex: number) => () => {
    props.onRowClick(item, rowIndex);
  };

  const renderRows = () => {
    return items.map((item, index) => {
      return (
        <Row
          key={`table-row-${index}`}
          index={index}
          columns={columns}
          onClick={onRowClick(item, index)}
          renderCell={renderCell}
          renderAfter={renderAfter}
        />
      );
    });
  };

  return (
    <StickyContainer>
      <div className={styles.table}>
        <Header
          columns={columns}
          renderCell={renderHeaderCell}
          onCellClick={onHeaderCellClick}
          sort={sort}
          sortDirection={sortDirection}
          onHeaderStickyStateChange={onHeaderStickyStateChange}
          topOffset={topOffset}
        />
        {items.length > 0 ? renderRows() : <div className={styles.notFoundBlock}>{t('Ничего не найдено')}</div>}
      </div>
    </StickyContainer>
  );
};

export default memo(Table, equal);
