export interface Division {
  title: string;
  value: DivisionName;
}

export type Divisions = Array<Division>;

type BattleTypeToDivisionsMap = {
  [key: string]: Divisions;
};

export const getDivisionsFromBattletype = (
  battleType: BattleTypeNameWithDivisions,
  t: (str: string) => string,
): Divisions => {
  const BattleTypeToDivisionsMap: BattleTypeToDivisionsMap = {
    ['pvp']: [
      {
        title: t('Все'),
        value: '',
      },
      {
        title: t('В одиночку'),
        value: 'solo',
      },
      {
        title: t('В отряде из двух'),
        value: 'div2',
      },
      {
        title: t('В отряде из трёх'),
        value: 'div3',
      },
    ],
    ['rank']: [
      {
        title: t('В одиночку'),
        value: 'solo',
      },
      {
        title: t('В отряде из двух'),
        value: 'div2',
      },
      {
        title: t('В отряде из трёх'),
        value: 'div3',
      },
    ],
    ['rank_old']: [
      {
        title: t('В одиночку'),
        value: 'solo',
      },
    ],
  };
  return BattleTypeToDivisionsMap[battleType] || [];
};
