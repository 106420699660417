import Button from '@wg/wows-react-uikit/Button';
import equal from 'fast-deep-equal/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { inViteToClan } from '~/Actions/ActionApp';
import { State } from '~/Reducers';

export interface ProfileState {
  isInviteSendMap: IsInviteSendMap;
  spaId?: string;
}

const stateSelector = (state: State): ProfileState => {
  return {
    isInviteSendMap: state.ReducerApp.isInviteSendMap,
    spaId: state.ReducerApp.spaId,
  };
};

const InviteButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector<State, ProfileState>(stateSelector, equal);
  const { isInviteSendMap, spaId } = state;

  const disabled = spaId && Object.keys(isInviteSendMap).includes(spaId);

  const onClick = () => {
    dispatch(inViteToClan());
  };

  return (
    <Button isFlat onClick={onClick} isDisabled={disabled}>
      {t('Пригласить в клан')}
    </Button>
  );
};

export default InviteButton;
