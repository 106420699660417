import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { dropAccount, dropFilters, getUsersSearch } from '~/Actions/ActionApp';
import history from '~/browserHistory';
import { ROUTES } from '~/constants';
import { State } from '~/Reducers';

export const navigateToProfile = (id: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getState: () => State,
) => {
  dispatch(dropFilters());
  dispatch(dropAccount());
  history.push(`/statistics/${id}`);
};

export const navigateToSearchResults = (query: string): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getState: () => State,
) => {
  dispatch(getUsersSearch(query));
  history.push(`${ROUTES.ROOT}?query=${query}`);
};

export const navigateToRoute = (route: string, clb: () => void): ThunkAction<void, {}, {}, AnyAction> => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => State,
) => {
  const state = getState();
  const { spaId } = state.ReducerApp;

  if (route == '/') {
    history.push(route);
  } else {
    history.push(`${route}/${spaId}`);
  }
  clb();
};
