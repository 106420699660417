import NotificationManager from '@wg/wows-react-uikit/NotificationManager';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation();

  const inviteSendMessage = t('Приглашение отправлено.');
  const inviteSendErrorMessage = t('Внутренняя ошибка при отправке приглашения.');

  const updateVisibilitySetting = t('Настройки видимости вашего профиля сохранены.');
  const errorUpdatingVisibilitySettings = t('Внутренняя ошибка при обновлениии настроек видимости.');

  window.notificationMessages = {
    inviteSendMessage,
    inviteSendErrorMessage,
    updateVisibilitySetting,
    errorUpdatingVisibilitySettings,
  };

  return <NotificationManager />;
};

export default Notifications;
