import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';

import { State } from '~/Reducers';

import styles from './DogTag.scss';
import DogTagClanTag from '~/Components/DogTag/DogTagСlanTag/DogTagClanTag';

export interface ProfileState {
  dollsMap: DollsMap;
  account?: Account;
}

const stateSelector = (state: State): ProfileState => {
  return {
    dollsMap: state.ReducerApp.dollsMap,
    account: state.ReducerApp.account,
  };
};

export interface DogTag_Props {
  clanTag?: string;
  small?: boolean;
}

const getColor = (color: string) => {
  return color.replace('0x', '#');
};

const DogTag = (props: DogTag_Props) => {
  const state = useSelector<State, ProfileState>(stateSelector, equal);
  const { clanTag } = props;
  const { dollsMap, account } = state;

  if (!account.dog_tag) return null;

  const symbol = dollsMap[account.dog_tag.symbol_id];
  const background = dollsMap[account.dog_tag.background_id];
  const backgroundColor = dollsMap[account.dog_tag.background_color_id];
  const borderColor = dollsMap[account.dog_tag.border_color_id];
  const texture = dollsMap[account.dog_tag.texture_id];
  const isColorizable = !!(background && background.isColorizable);
  const symbolImage = get(symbol, 'icons.medium');
  let backgroundImage = get(background, 'icons.medium');
  let textureData = null;
  let borderImage = null;
  const hasBg = !!backgroundImage;

  if (isColorizable && texture && Array.isArray(texture.textureData)) {
    textureData = texture.textureData.find((el) => el.id === background.id);
    backgroundImage = textureData.background.medium;
    borderImage = textureData.border.medium;
  }

  if (!symbolImage) return null;
  if (isColorizable && !hasBg) return null;

  const borderColorCode = getColor(get(borderColor, 'color', ''));
  const symbolOffsetY = hasBg && background.symbolOffsetY ? background.symbolOffsetY * 100 : '';

  const hasBackgroundClanTag = hasBg && get(background, 'showClanTag', false);
  const hasSymbolClanTag = get(symbol, 'showClanTag', false);
  const showClanTag = hasBackgroundClanTag || hasSymbolClanTag;
  const clanTagColor =
    get(background, 'clanTag.fontColor') || get(symbol, 'clanTag.fontColor') || borderColorCode || '#FFFFFF';
  const clanTagX = get(background, 'clanTag.x') || get(symbol, 'clanTag.x');
  const clanTagY = get(background, 'clanTag.y') || get(symbol, 'clanTag.y');

  const wrapperClasses = classnames(styles.wrapper, {
    [styles.wrapperSmall]: !!props.small,
    [styles.withClanTag]: showClanTag,
    [styles.withBg]: hasBg,
  });

  return (
    <div className={wrapperClasses}>
      {backgroundImage && (
        <div
          className={classnames(styles.background, styles.component, isColorizable && styles.colorizable)}
          style={{
            // @ts-ignore
            '--image': `url('${backgroundImage}')`,
            backgroundColor: getColor(get(backgroundColor, 'color', '')),
          }}
        >
          <img src={backgroundImage} />
        </div>
      )}
      {borderImage && (
        <div
          className={classnames(styles.border, styles.component, isColorizable && styles.colorizable)}
          style={{
            // @ts-ignore
            '--image': `url('${borderImage}')`,
            backgroundColor: borderColorCode,
          }}
        >
          <img src={borderImage} />
        </div>
      )}
      {symbolImage && (
        <div
          className={classnames(styles.symbol, styles.component)}
          style={{
            top: `${symbolOffsetY}%`,
          }}
        >
          <img src={symbolImage} />
        </div>
      )}
      {showClanTag && (
        <DogTagClanTag className={styles.clanTag} tag={clanTag} color={clanTagColor} x={clanTagX} y={clanTagY} />
      )}
    </div>
  );
};

export default DogTag;
