const defaultStatistics: BattleStat = {
  art_agro: 0,
  battles_count: 0,
  battles_count_510: 0,
  capture_points: 0,
  control_captured_points: 0,
  control_dropped_points: 0,
  damage_dealt: 0,
  damage_dealt_to_buildings: 0,
  dropped_capture_points: 0,
  exp: 0,
  frags: 0,
  frags_by_atba: 0,
  frags_by_main: 0,
  frags_by_planes: 0,
  frags_by_ram: 0,
  frags_by_tpd: 0,
  frags_by_dbomb: 0,
  frags_by_mines: 0,
  hits_by_atba: 0,
  hits_by_bomb: 0,
  hits_by_main: 0,
  hits_by_rocket: 0,
  hits_by_skip: 0,
  hits_by_tbomb: 0,
  hits_by_tpd: 0,
  losses: 0,
  max_damage_dealt: 0,
  max_damage_dealt_to_buildings: 0,
  max_damage_dealt_to_buildings_vehicle: 0,
  max_damage_dealt_vehicle: 0,
  max_exp: 0,
  max_exp_vehicle: 0,
  max_frags: 0,
  max_frags_by_atba: 0,
  max_frags_by_atba_vehicle: 0,
  max_frags_by_main: 0,
  max_frags_by_main_vehicle: 0,
  max_frags_by_planes: 0,
  max_frags_by_planes_vehicle: 0,
  max_frags_by_ram: 0,
  max_frags_by_ram_vehicle: 0,
  max_frags_by_tpd: 0,
  max_frags_by_tpd_vehicle: 0,
  max_frags_vehicle: 0,
  max_planes_killed: 0,
  max_planes_killed_vehicle: 0,
  max_premium_exp: 0,
  max_premium_exp_vehicle: 0,
  max_scouting_damage: 0,
  max_scouting_damage_vehicle: 0,
  max_ships_spotted: 0,
  max_ships_spotted_vehicle: 0,
  max_suppressions_count: 0,
  max_suppressions_count_vehicle: 0,
  max_total_agro: 0,
  max_total_agro_vehicle: 0,
  original_exp: 0,
  planes_killed: 0,
  premium_exp: 0,
  scouting_damage: 0,
  ships_spotted: 0,
  shots_by_atba: 0,
  shots_by_bomb: 0,
  shots_by_main: 0,
  shots_by_rocket: 0,
  shots_by_skip: 0,
  shots_by_tbomb: 0,
  shots_by_tpd: 0,
  suppressions_count: 0,
  survived: 0,
  team_control_captured_points: 0,
  team_control_dropped_points: 0,
  tpd_agro: 0,
  win_and_survived: 0,
  wins: 0,
};

export default defaultStatistics;
