export const permissions = {
  RENAME_CLAN: 'edit_info',
  CHANGE_SETTINGS: 'change_settings',
  CHANGE_ROLE: 'change_role',
  CHANGE_COMMANDER: 'change_commander',
  HANDLE_INVITES: 'handle_invites',
  INVITE: 'invite',
  REMOVE_MEMBER: 'remove_member',
  REMOVE_CLAN: 'remove_clan',
  SUPPLY_VIEW_CLAN_ADMITTANCE: 'supply_view_clan_admittance',
  SUPPLY_APPLY_MODIFIER: 'supply_apply_modifier',
  CWARS_TOGGLE_PARTICIPATION: 'cwars_toggle_participation',
};

export const getRoles = (t: (s: string) => string): Roles => {
  return {
    commander: {
      name: 'commander',
      rank: 10,
      localized_name: t('Командующий флотом'),
      order: 0,
      permissions: [
        permissions.RENAME_CLAN,
        permissions.CHANGE_SETTINGS,
        permissions.CHANGE_ROLE,
        permissions.CHANGE_COMMANDER,
        permissions.HANDLE_INVITES,
        permissions.INVITE,
        permissions.REMOVE_MEMBER,
        permissions.REMOVE_CLAN,
        permissions.SUPPLY_VIEW_CLAN_ADMITTANCE,
        permissions.SUPPLY_APPLY_MODIFIER,
        permissions.CWARS_TOGGLE_PARTICIPATION,
      ],
    },
    executive_officer: {
      name: 'executive_officer',
      rank: 9,
      localized_name: t('Заместитель командующего'),
      order: 1,
      permissions: [
        permissions.RENAME_CLAN,
        permissions.CHANGE_SETTINGS,
        permissions.CHANGE_ROLE,
        permissions.HANDLE_INVITES,
        permissions.INVITE,
        permissions.REMOVE_MEMBER,
        permissions.SUPPLY_VIEW_CLAN_ADMITTANCE,
        permissions.SUPPLY_APPLY_MODIFIER,
        permissions.CWARS_TOGGLE_PARTICIPATION,
      ],
    },
    recruitment_officer: {
      name: 'recruitment_officer',
      rank: 5,
      localized_name: t('Военком'),
      order: 5,
      permissions: [permissions.HANDLE_INVITES, permissions.INVITE],
    },
    commissioned_officer: {
      name: 'commissioned_officer',
      rank: 0,
      localized_name: t('Кадровый офицер'),
      order: 8,
      permissions: [],
    },
    officer: {
      name: 'officer',
      rank: 0,
      localized_name: t('Офицер'),
      order: 9,
      permissions: [],
    },
    private: {
      name: 'private',
      rank: 0,
      localized_name: t('Курсант'),
      order: 10,
      permissions: [],
    },
  };
};
