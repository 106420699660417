import { BattleType } from '@wg/wows-entities/vortex';
import equal from 'fast-deep-equal/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { setBattleType } from '~/Actions/ActionApp';
import Select from '~/Components/Select/Select';
import { BATTLE_TYPES, DIVISIONAL_BATTLE_TYPES } from '~/constants';
import { State } from '~/Reducers';
import { getAvailableBattleTypes, getAvailableStatBattleTypes } from '~/utils/statistics';

import DivisionPicker from '../DivisionPicker/DivisionPicker';
import styles from './BattleTypePicker.scss';

export interface BattleTypePickerProps {
  noNavigation?: boolean;
  allTypes?: boolean;
  withDivisionPicker?: boolean;
}

export interface BattleTypePickerState {
  account?: Account;
  battleTypeRaw: BattleTypeName;
  battleTypesMap: BattleTypesMap;
}

const stateSelector = (state: State): BattleTypePickerState => {
  return {
    account: state.ReducerApp.account,
    battleTypeRaw: state.ReducerApp.battleTypeRaw,
    battleTypesMap: state.ReducerApp.battleTypesMap,
  };
};

const BattleTypePicker = (props: BattleTypePickerProps) => {
  const state = useSelector<State, BattleTypePickerState>(stateSelector, equal);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<LocationParams>();
  const { account, battleTypeRaw, battleTypesMap } = state;

  const availableBattleTypes = getAvailableBattleTypes(account, props.allTypes);
  const battleTypesTitles = {
    pve: t('Кооперативные бои'),
    pvp: t('Случайные бои'),
    rank: t('Ранговые бои'),
    rank_old: t('Ранговые бои (Старые)'),
    club: t('Командные бои'),
    clan: t('Клановые бои'),
    brawl: t('Клановый блиц'),
  };

  const filteredBattleTypes = BATTLE_TYPES.filter((battleTypeListItem) => {
    return availableBattleTypes.includes(battleTypeListItem.id);
  });

  const battleTypes = filteredBattleTypes.map((battleTypeListItem) => {
    return {
      title: battleTypesTitles[battleTypeListItem.id],
      value: battleTypeListItem.id,
    };
  });

  let battleTypeIndex = 0;
  battleTypes.forEach((battleTypeItem, index) => {
    if (battleTypeItem.value === battleTypeRaw) {
      battleTypeIndex = index;
    }
  });

  if (battleTypeIndex !== selectedIndex) {
    setSelectedIndex(battleTypeIndex);
  }

  const renderValue = () => {
    const currentBattleType: BattleType = battleTypesMap[filteredBattleTypes[selectedIndex].selector];
    const iconUrl = currentBattleType ? currentBattleType.icons.default : '';
    const title = battleTypes[selectedIndex] ? battleTypes[selectedIndex].title : ' ';
    return (
      <div className={styles.value}>
        <div
          className={styles.battleTypeIcon}
          style={{
            backgroundImage: `url("${iconUrl}")`,
          }}
        />
        {title}
      </div>
    );
  };

  const renderItem = (index: number) => {
    return battleTypes[index].title;
  };

  const onSelectHandler = (index: number) => {
    setSelectedIndex(index);
    const value = battleTypes[index] ? battleTypes[index].value : undefined;
    if (props.noNavigation) {
      dispatch(setBattleType(value as BattleTypeName));
    } else {
      if (value && params.id) {
        history.push(`/statistics/${params.id}/${value}`);
      }
    }
  };

  return (
    battleTypes.length !== 0 && (
      <>
        <Select
          maxItemsCount={battleTypes.length}
          items={battleTypes.map((item) => item.value)}
          selectedIndex={battleTypeIndex}
          onSelect={onSelectHandler}
          renderValue={renderValue}
          renderItem={renderItem}
        />
        {props.withDivisionPicker && battleTypes && DIVISIONAL_BATTLE_TYPES.includes(battleTypeRaw) && (
          <DivisionPicker availableStatsBattleTypes={getAvailableStatBattleTypes(account)} noNavigation={true} />
        )}
      </>
    )
  );
};

export default BattleTypePicker;
